import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import NavbarNew from "../components/NavbarNew"
import Footer from "../components/_App/Footer"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import CaseStudyTestimonial from "../components/case-study-testimonial"
import Loadable from "@loadable/component"
import CaseStudySolutionObjective from "../components/case-study-solution-objective"
import CaseStudyList from "../components/case-study-list"
import GetInTouch from "../components/get-in-touch"
import webIcon from "../assets/images/globalization.png"
import androidIcon from "../assets/images/android.png"
import appleIcon from "../assets/images/apple.png"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))
import link from "../assets/images/link.png"
import SharePost from "../components/share-post"

const CaseStudyTemplate = ({ data }) => {
  // console.log("CaseStudyTemplateData", data)
  const caseStudyMain = data?.caseStudyMain?.nodes[0]?.childMdx
  const slug = data?.caseStudyMain?.nodes[0]?.childMdx.slug
  const {
    title,
    seoTitle,
    thumbImg,
    thumbImgAlt,
    bannerImg,
    bannerImgAlt,
    summary,
    seoSummary,
    technologiesUsed,
    businessUseCase,
    industry,
    businessFunction,
    engagementDuration,
    iosAppStoreLink,
    androidAppStoreLink,
    webPortalLink,
    servicesProvided,
    teamSize,
    screenShots,
    screenShotsTablet,
    testimonial,
    client,
    key,
    keywords,
    relatedCaseStudies,
  } = caseStudyMain?.frontmatter

  const businessProblem = data?.businessProblem?.nodes[0]?.childMdx
  const impactOfSolution = data?.impactOfSolution?.nodes[0]?.childMdx
  const aboutClient = data?.aboutClient?.nodes[0]?.childMdx
  // const caseStudyList = data?.caseStudies?.nodes.filter(
  //   (node: any) => node.relativeDirectory.search("/") === -1
  // )
  const caseStudyList: any = data?.caseStudies?.nodes.filter(
    (node: any) =>
      node.relativeDirectory.search("/") === -1 &&
      node?.childMdx.frontmatter.key !== key
  )
  // console.log("caseStudyList", caseStudyList)

  const relatedCaseStudyList: any = []
  relatedCaseStudies?.forEach((list: any) => {
    for (let i = 0; i < caseStudyList.length; i++) {
      if (list === caseStudyList[i].childMdx.frontmatter.key) {
        relatedCaseStudyList.push(caseStudyList[i])
      }
    }
  })
  relatedCaseStudyList.length = 3

  const linkId = (id: any) => {
    return id
      .replace(/[^a-zA-Z ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase()
  }

  const testimonialArr = data?.testimonial?.nodes
  const testimonialCarouselOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 7000,
    items: 1,
    navText: ["", ""],
    navClass: ["owl-pre-icon", "owl-next-icon"],
  }
  const clientsCarouselOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 3,
    margin: 30,
    // navClass: ["owl-pre-icon", "owl-next-icon"],
    // navText: ["", ""],
  }

  const solutionArr = data?.allCaseStudiesFile?.nodes
    ?.filter((list: any) => list.childMdx !== null)
    .filter((list: any) => list.childMdx.slug.search("solution") !== -1)
    .filter(
      (list: any) => list.childMdx.slug.search("impact-of-solution") === -1
    )
    .filter((list: any) => list.childMdx.slug.search(key) !== -1)
  // console.log("solutionArr", solutionArr)
  const solutionData =
    solutionArr &&
    solutionArr.length > 0 &&
    solutionArr.filter(
      (list: any) => list.childMdx.slug.search("objective") === -1
    )[0]?.childMdx
  // console.log("solutionData", solutionData)

  const solutionObjectives =
    solutionArr &&
    solutionArr.length > 0 &&
    solutionArr.filter(
      (list: any) => list.childMdx.slug.search("objective") !== -1
    )
  // console.log("solutionObjectives", solutionObjectives)

  const screenshotsCarouselOptions = {
    loop: screenShots && screenShots.length > 1 ? true : false,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    autoplayTimeout: 5000,
    items: 1,
    navText: ["", ""],
    navClass: ["owl-pre-icon", "owl-next-icon"],
  }

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoSummary}
        ogImage={thumbImg?.publicURL}
        keywords={keywords}
        url={`/our-work/${slug}`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      <div className="case-study-banner">
        <div className="container">
          <div className="case-study-content-wrapper">
            <div className="case-study-content">
              <h1>{title}</h1>
              <p>{summary}</p>
            </div>
          </div>
        </div>
        <div
          className="banner-image"
          style={{ background: `url(${bannerImg?.publicURL})` }}
        >
          <div className="overlay"></div>
          <figure className="d-none">
            <img src={bannerImg?.publicURL} alt={bannerImgAlt} />
          </figure>
        </div>
      </div>

      <div className="case-study-area ptb-50 p-b-sm-0">
        <div className="container">
          <div className="row responsive-wrap ">
            <div className="col-lg-8 col-md-12">
              {caseStudyMain && (
                <section className="introduction pb-50 " id="introduction">
                  <div className="section-title">
                    <h2 className="hover-link">
                      <span>
                        <a href="#introduction">
                          <img src={link} alt="" />
                        </a>
                        <a name="introduction">Introduction</a>
                      </span>
                    </h2>
                    <div className="bar"></div>
                  </div>
                  <MDXRenderer>{caseStudyMain?.body}</MDXRenderer>
                </section>
              )}

              {businessProblem && (
                <section
                  className="business-problem pb-50"
                  id={linkId(businessProblem?.frontmatter?.title)}
                >
                  <div className="section-title">
                    <h2 className="hover-link">
                      <span>
                        <a
                          href={`#${linkId(
                            businessProblem?.frontmatter?.title
                          )}`}
                        >
                          <img src={link} alt="" />
                        </a>
                        {businessProblem?.frontmatter?.title}
                      </span>
                    </h2>
                    <div className="bar"></div>
                  </div>
                  <MDXRenderer>{businessProblem?.body}</MDXRenderer>
                </section>
              )}

              {solutionData && (
                <section
                  className="how-we-solved m-b-sm-16"
                  id={linkId(solutionData?.frontmatter?.title)}
                >
                  <div className="section-title">
                    <h2 className="hover-link">
                      <span>
                        <a
                          href={`#${linkId(solutionData?.frontmatter?.title)}`}
                        >
                          <img src={link} alt="" />
                        </a>
                        <a name={linkId(solutionData?.frontmatter?.title)}>
                          {solutionData?.frontmatter?.title}
                        </a>
                      </span>
                    </h2>

                    <div className="bar"></div>
                  </div>
                  <MDXRenderer>{solutionData?.body}</MDXRenderer>
                </section>
              )}
            </div>
            <div className="col-lg-4 col-md-12">
              <aside className="widget-area">
                <section>
                  <div>
                    {data?.caseStudyMain?.nodes &&
                      data?.caseStudyMain?.nodes.length > 0 && (
                        <div className="widget widget_share_post single-footer-widget">
                          {/* {console.log(
                            "our work",
                            data?.caseStudyMain?.nodes[0]?.childMdx?.slug
                          )} */}
                          <SharePost
                            data={`our-work/${data?.caseStudyMain?.nodes[0]?.childMdx?.slug}`}
                          />
                        </div>
                      )}
                  </div>
                </section>
                <section className="widget widget_search">
                  <ul className="project-development-info">
                    <li className="item">
                      <p className="lable">Industry</p>
                      <p className="value">{industry}</p>
                    </li>
                    <li className="item">
                      <p className="lable">Business Function</p>
                      <p className="value">{businessFunction}</p>
                    </li>
                    <li className="item">
                      <p className="lable">Business Use Case</p>
                      <p className="value">{businessUseCase}</p>
                    </li>

                    <li className="item">
                      <p className="lable">Technologies Used</p>
                      <p className="value">{technologiesUsed}</p>
                    </li>
                    <li className="item">
                      <p className="lable">Services Provided</p>
                      <p className="value">{servicesProvided}</p>
                    </li>
                    {(webPortalLink ||
                      androidAppStoreLink ||
                      iosAppStoreLink) && (
                      <li className="item">
                        <p className="lable">Link to deployment</p>
                        <p className="value deployment-links d-flex">
                          {webPortalLink && (
                            <span className="web">
                              <Link
                                to={webPortalLink}
                                target="_blank"
                                rel="nofollow"
                              >
                                <img src={webIcon} alt="webIcon" />
                              </Link>
                            </span>
                          )}
                          {androidAppStoreLink && (
                            <span className="android">
                              <Link
                                to={androidAppStoreLink}
                                target="_blank"
                                rel="nofollow"
                              >
                                <img src={androidIcon} alt="androidIcon" />
                              </Link>
                            </span>
                          )}
                          {iosAppStoreLink && (
                            <span className="apple">
                              <Link
                                to={iosAppStoreLink}
                                target="_blank"
                                rel="nofollow"
                              >
                                <img src={appleIcon} alt="appleIcon" />
                              </Link>
                            </span>
                          )}
                        </p>
                      </li>
                    )}
                    <li className="item">
                      <p className="lable">Engagement</p>
                      <p className="value">{engagementDuration}</p>
                    </li>
                    <li className="item">
                      <p className="lable">Team</p>
                      <p className="value">{teamSize}</p>
                    </li>
                  </ul>
                </section>

                {aboutClient && (
                  <section className="widget" id="about-the-client">
                    <h3 className="widget-title">About the Client</h3>
                    {/* <MDXRenderer>{aboutClient?.body}</MDXRenderer> */}
                    {/* <section className="clients pt-80 pb-50 bg-f7fafd" id="clients"> */}
                    {/* <div className="container"> */}
                    {/* <div className="section-title">
                        <h2 className="hover-link">
                          <span>
                            <a href="#clients">
                              <img src={link} alt="" />
                            </a>
                            Clients
                          </span>
                        </h2>
                        <div className="bar"></div>
                        <p>
                          We pride ourselves to work with business who are
                          market leaders of their industries
                        </p>
                      </div> */}

                    <div className="single-services-box cs-pg client-list">
                      <div className="icon" style={{ background: "none" }}>
                        <img
                          src={aboutClient?.frontmatter?.logo?.publicURL}
                          alt=""
                        />
                      </div>
                      <h3>
                        <p className=" text-dark lh-1">
                          {" "}
                          {aboutClient?.frontmatter?.title}
                        </p>
                        <p className="badge rounded-pill industry-badge">
                          {aboutClient?.frontmatter?.industry}
                        </p>
                        <span>
                          {aboutClient?.frontmatter?.size},{" "}
                          {aboutClient?.frontmatter?.location}
                        </span>
                      </h3>
                      <MDXRenderer>{aboutClient?.body}</MDXRenderer>
                    </div>
                    {/* </div> */}
                  </section>
                )}

                {testimonialArr[0]?.childMdx?.frontmatter.title && (
                  <section
                    className="widget m-bt-md-15"
                    id="clients-testimonial"
                  >
                    <h3 className="widget-title">Client's Testimonial</h3>
                    <div
                      className="bigdata-feedback-area"
                      style={{ padding: "10px 35px 5px 10px" }}
                    >
                      <OwlCarousel
                        className="owl-carousel owl-theme"
                        {...testimonialCarouselOptions}
                      >
                        {testimonialArr &&
                          testimonialArr.length > 0 &&
                          testimonialArr.map((list: any, index: number) => {
                            // console.log("list", list)
                            return (
                              <CaseStudyTestimonial
                                data={list?.childMdx?.frontmatter}
                                key={index + 1}
                              />
                            )
                          })}
                      </OwlCarousel>
                    </div>
                  </section>
                )}
              </aside>
            </div>
          </div>
        </div>
      </div>

      {
        <>
          <div className="case-study-screen-shots d-none d-sm-none d-md-block">
            {screenShots && screenShots.length > 0 && (
              <OwlCarousel
                className="owl-carousel owl-theme full-size"
                {...screenshotsCarouselOptions}
              >
                {screenShots.map((list: any, index: number) => (
                  <img src={list?.publicURL} alt="" key={index + 1} />
                ))}
              </OwlCarousel>
            )}
          </div>
          <div className="case-study-screen-shots d-block d-sm-block d-md-none">
            {screenShotsTablet && screenShotsTablet.length > 0 && (
              <OwlCarousel
                className="owl-carousel owl-theme"
                {...screenshotsCarouselOptions}
              >
                {screenShotsTablet.map((list: any, index: number) => (
                  <img src={list?.publicURL} alt="" key={index + 1} />
                ))}
              </OwlCarousel>
            )}
          </div>
        </>
      }

      <div className="case-study-solution-achieved ptb-50 p-b-sm-0">
        <div className="container">
          {solutionObjectives &&
            solutionObjectives?.length > 0 &&
            solutionObjectives?.map((objective: any, index: number) => (
              <CaseStudySolutionObjective
                data={objective?.childMdx}
                type={(index + 1) % 2 == 0 ? "even" : "odd"}
                key={index + 1}
                linkId={linkId}
              />
            ))}
        </div>
      </div>

      <div className="case-study-area">
        <div className="container">
          <section
            className="impact-of-solution pb-50"
            id={linkId(impactOfSolution?.frontmatter?.title)}
          >
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href={`#${linkId(impactOfSolution?.frontmatter?.title)}`}>
                    <img src={link} alt="" />
                  </a>
                  {impactOfSolution?.frontmatter?.title}
                </span>
              </h2>
              <div className="bar"></div>
            </div>
            {impactOfSolution && (
              <MDXRenderer>{impactOfSolution?.body}</MDXRenderer>
            )}

            <div className="case-study-clutch-review">
              {testimonialArr && testimonialArr.length > 0 && (
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  {...testimonialCarouselOptions}
                >
                  {testimonialArr.map((list: any, index: number) => {
                    // console.log("list", list)
                    return (
                      <div className="review-image" key={index + 1}>
                        <a
                          href={list?.childMdx?.frontmatter?.link}
                          target="_blank"
                        >
                          <img
                            src={
                              list?.childMdx?.frontmatter?.preview?.publicURL
                            }
                            alt=""
                          />
                        </a>
                      </div>
                    )
                  })}
                </OwlCarousel>
              )}
            </div>
          </section>
        </div>
      </div>

      {data?.caseStudyMain?.nodes && data?.caseStudyMain?.nodes.length > 0 && (
        <div
          className="case-studies ptb-80 p-t-sm-0 p-b-sm-0"
          id="related-case-studies"
        >
          <div className="container">
            <div className="section-title">
              <h2 className="hover-link">
                <span>
                  <a href={`#related-case-studies`}>
                    <img src={link} alt="" />
                  </a>
                  Related Case Studies
                </span>
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row justify-content-center">
              {relatedCaseStudyList.map((node: any, index: number) => (
                <div className="col-lg-4 col-sm-6 col-md-6" key={index + 1}>
                  <CaseStudyList data={node?.childMdx} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <GetInTouch />
      <Footer />
    </Layout>
  )
}

export default CaseStudyTemplate
export const query = graphql`
  query caseStudyDetails(
    $slug: String
    $businessProblemSlug: String
    $solutionImpactSlug: String
    $client: String
    $testimonial: String
  ) {
    caseStudyMain: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
        childMdx: { slug: { eq: $slug } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            seoTitle
            thumbImg {
              publicURL
            }
            thumbImgAlt
            bannerImg {
              publicURL
            }
            bannerImgAlt
            key
            client
            engagementDuration
            testimonial
            webPortalLink
            teamSize
            summary
            seoSummary
            keywords
            relatedCaseStudies
            screenShots {
              publicURL
            }
            screenShotsTablet {
              publicURL
            }
            androidAppStoreLink
            iosAppStoreLink
            technologiesUsed
            servicesProvided
            industry
            businessFunction
            businessUseCase
            thumbImg {
              publicURL
            }
            thumbImgAlt
          }
          slug
          body
        }
      }
    }
    businessProblem: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        childMdx: { slug: { eq: $businessProblemSlug } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
          }
          body
        }
      }
    }
    impactOfSolution: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        childMdx: { slug: { eq: $solutionImpactSlug } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
          }
          body
        }
      }
    }
    aboutClient: allFile(
      filter: {
        sourceInstanceName: { eq: "clients" }
        name: { eq: "index" }
        childMdx: { frontmatter: { key: { eq: $client } } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            size
            industry
            logo {
              publicURL
            }
            location
          }
          body
        }
        relativeDirectory
      }
    }
    testimonial: allFile(
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        name: { eq: "index" }
        childMdx: { frontmatter: { key: { eq: $testimonial } } }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            client
            reviewerDesignation
            title
            summary
            preview {
              publicURL
            }
            link
          }
        }
      }
    }
    allCaseStudiesFile: allFile(
      filter: { sourceInstanceName: { eq: "caseStudies" } }
      sort: { fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            screens {
              publicURL
            }
            order
          }
          slug
          body
        }
      }
    }
    caseStudies: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            key
            thumbImg {
              publicURL
            }
            thumbImgAlt
            summary
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
  }
`
