import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Loadable from "@loadable/component"
import link from "../assets/images/link.png"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const CaseStudySolutionObjective = ({ data, type, linkId }: any) => {
  const objectiveCarouselOptions = {
    loop: data?.frontmatter?.screens.length > 1 ? true : false,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    autoplayTimeout: 5000,
    items: 1,
    navText: ["", ""],
    navClass: ["owl-pre-icon", "owl-next-icon"],
  }
  // console.log("data", data)
  return (
    <div className={`row pb-50 ${type === "even" ? "row-reverse" : ""}`}>
      <div className="col-md-6" id={linkId(data?.frontmatter?.title)}>
        <div className="section-sub-title">
          <h3 className="hover-link">
            <span>
              <a href={`#${linkId(data?.frontmatter?.title)}`}>
                <img src={link} alt="" />
              </a>
              {data?.frontmatter?.title}
            </span>
          </h3>
        </div>
        <span className="dis-sm-none">
          {" "}
          {data?.body && <MDXRenderer>{data?.body}</MDXRenderer>}
        </span>
      </div>
      <div className="col-md-6">
        <div className="solution-carousel">
          {data?.frontmatter?.screens && data?.frontmatter?.screens.length > 0 && (
            <OwlCarousel
              className="owl-carousel owl-theme"
              {...objectiveCarouselOptions}
            >
              {data?.frontmatter?.screens.map((screen: any, index: number) => {
                return <img src={screen?.publicURL} alt="" key={index + 1} />
              })}
            </OwlCarousel>
          )}
        </div>
      </div>
      <span className="hide-lg">
        {" "}
        {data?.body && <MDXRenderer>{data?.body}</MDXRenderer>}
      </span>
    </div>
  )
}

export default CaseStudySolutionObjective
