import React from "react"

const CaseStudyTestimonial = ({ data }: any) => {
  const { title, summary, client, reviewerDesignation, link } = data
  return (
    <div className="single-feedback-box">
      <div className="feedback-desc">
        <p className="intro">{title}</p>
        <p>{summary}</p>
        <span>
          <a href={link} rel="no-link" target="_blank">
            Read full review on clutch
          </a>
        </span>
      </div>
      <div className="client-info pl-0">
        <h3>{client}</h3>
        <span>{reviewerDesignation}</span>
      </div>
    </div>
  )
}

export default CaseStudyTestimonial
